import { useContext, ref } from '@nuxtjs/composition-api'

export enum StorageKeys {
  SearchTerm = 'search_terms'
}

const flagVat = ref<boolean | null>(null)

export const useUniversal = () => {
  const { app } = useContext()

  const checkFlagVat = async () => {
    if (flagVat.value === null) {
      const inclVat = await app.$api.preference.getVatSetting()
      flagVat.value = inclVat?.includeVat ?? false
    }
  }

  const setFlagVat = async (val: boolean) => {
    const inclVat = await app.$api.preference.setVat(val)
    flagVat.value = inclVat?.includeVat ?? false
  }

  return {
    flagVat,

    setFlagVat,
    checkFlagVat,
  }
}
